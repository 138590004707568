<template>
    <div>
        <TopMenuNotification title="Zeskanuj książkę"/>

        <QuaggaScanner :onDetected="logIt" :readerTypes="['ean_reader']"></QuaggaScanner>

        <div style="position: absolute; top: 0; left: 0; width: 100%" v-if="detected == true">
            <Loader />
        </div>

        <div class="button-item-container">
            <div class="container">
                <div v-if="isNotFound">
                    <div class="empty-container">
                        <div class="empty-messages-info text-center">
                            <b>Przykro nam. <br> Nie znaleźliśmy Twojej książki</b>
                        </div>
                        <div class="empty-container" >
                            <div class="quote messages-quote">
                                Właściwa droga i łatwa droga<br> nigdy nie wiodą tą samą ścieżką
                            </div>
                            <div>
                                <div class="text-center mb-3">
                                    Dodaj książkę manualnie. <br> To potrwa tylko chwilę.
                                </div>
                                <router-link to="/dodaj-ksiazke">
                                    <button type="button" class="btn-black-full w-100 mt-3 mb-4">Dodaj książkę</button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TopMenuNotification from '../components/TabsNotification'
import QuaggaScanner from '../components/QuaggaScanner'
import router from '../router'
import Loader from '../components/Loader'

export default {
  name: 'ScanBook',
  components: {
    TopMenuNotification,
    QuaggaScanner,
    Loader
  },
  data () {
    return {
      isNotFound: false,
      detected: false,
      ratio: window.devicePixelRatio || 1,
      w: document.documentElement.clientWidth,
      h: document.documentElement.clientHeight,
      readerSize: {
        width: this.w,
        height: this.h
      },
      detecteds: []
    }
  },
  methods: {
    logIt (data) {
      if (this.detected === true) {
        return
      }

      if (data.codeResult.code) {
        this.detected = true

        this.$https('/search/bookno?no=' + data.codeResult.code, {
          method: 'GET',
          mode: 'no-cors',
          crossDomain: true
        }).then(response => {
          if (response.data.response.length === 0) {
            this.isNotFound = true
            this.detected = false
          } else {
            router.push({ name: 'BooksDetails', params: { id: response.data.response[0].id } })
          }
        })
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
    .app-item-middle {
        background-color: #000;
        color: #fff;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &.shown {
            position: absolute;
            top: 40%;
        }
    }

    .top-menu-visible {
        position: relative;
    }

    .button-item-container {
        width: 100%;
        color: #000;
        background-color: #f1f1f1;
        /*z-index: 100;*/
        position: absolute;
        bottom: 114px;
    }
</style>
