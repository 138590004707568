<template>
    <div id="interactive" class="viewport scanner">
        <video/>
        <canvas class="drawingBuffer"/>
        <div class="sights"></div>
    </div>
</template>

<script>
import Quagga from 'quagga'

export default {
  name: 'QuaggaScanner',
  props: {
    onDetected: {
      type: Function,
      default (result) {
        console.log('detected: ', result)
      }
    },
    onProcessed: {
      type: Function,
      default (result) {
      }
    },
    readerTypes: {
      type: Array,
      default: () => ['code_128_reader']
    },
    readerSize: {
      type: Object,
      default: () => ({
        width: 640,
        height: 480
      }),
      validator: o =>
        typeof o.width === 'number' && typeof o.height === 'number'
    },
    aspectRatio: {
      type: Object,
      default: () => ({
        min: 1,
        max: 2
      }),
      validator: o => typeof o.min === 'number' && typeof o.max === 'number'
    }
  },
  data: function () {
    return {
      quaggaState: {
        inputStream: {
          type: 'LiveStream',
          constraints: {
            width: this.readerSize.width,
            height: this.readerSize.height,
            facingMode: 'environment',
            aspectRatio: { min: 1, max: 2 }
          }
        },
        locator: {
          patchSize: 'medium',
          halfSample: true
        },
        numOfWorkers: 2,
        frequency: 10,
        decoder: {
          readers: this.readerTypes
        },
        locate: true
      }
    }
  },
  watch: {
    onDetected: function (oldValue, newValue) {
      if (oldValue) Quagga.offDetected(oldValue)
      if (newValue) Quagga.onDetected(newValue)
    },
    onProcessed: function (oldValue, newValue) {
      if (oldValue) Quagga.offProcessed(oldValue)
      if (newValue) Quagga.onProcessed(newValue)
    }
  },
  mounted: function () {
    Quagga.init(this.quaggaState, function (err) {
      if (err) {
        return console.error(err)
      }
      Quagga.start()
    })
    Quagga.onDetected(this.onDetected)
    Quagga.onProcessed(this.onProcessed)
  },
  destroyed: function () {
    if (this.onDetected) Quagga.offDetected(this.onDetected)
    if (this.onProcessed) Quagga.offProcessed(this.offProcessed)
    Quagga.stop()
  }
}
</script>

<style scoped>
    .viewport {
        position: absolute;
        width: 100vw;
        height: calc(100vh - 153px);
        top: 93px;
        left: 0;
    }

    .viewport canvas,
    .viewport video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .sights{
        background: transparent url('../assets/images/sights.png') no-repeat center;
        background-size: 67%;
        position:absolute;
        width: 100%;
        height: 100%;
    }
</style>
